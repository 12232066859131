import React, { useEffect, useState } from "react";
import axios from "axios";
import { classnames } from "../../utils/general";
import { languageOptions } from "../../constants/languageOptions";
import {useNavigate} from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { defineTheme } from "../../lib/defineTheme";
import useKeyPress from "../../hooks/useKeyPress";

import ArenaInstruction from "../../models/ArenaInstruction"

import CodeEditorWindow from "./CodeEditorWindow";
import OutputWindow from "./OutputWindow";
import ThemeDropdown from "./ThemeDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import CoreNavBar from "../nav/CoreNavBar";
import {Container} from "react-grid-system";
import BotLoadModal from "./BotLoadModal";
import Editor from "@monaco-editor/react";
import {Card, Form} from "react-bootstrap";
import hostName from "../../constants/hosts";
import DocumentationPage from "../documentation/DocumentationPage";


const javascriptDefault = `# Add your code below! 
let bot_id = _ID
print(bot_id)
if _FRONT_NEIGHBOR == "ENEMY":
    return $INFECT
elif _FRONT_NEIGHBOR == "WALL" or _FRONT_NEIGHBOR == "FRIEND":
    return $TURN_RIGHT
else:
    return $MOVE
END

`;

const BotBuilder = () => {
  const host = hostName

  const [isAuth, setIsAuth] = useState(false);
  const [code, setCode] = useState(javascriptDefault);
  const [botName, setBotName] = useState('new_bot');
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(null);
  const [theme, setTheme] = useState("cobalt");
  const [language, setLanguage] = useState(languageOptions[0]);

  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");

  const [showModal, setShowModal] = useState(false);
  const [hasUserBots, setHasUserBots] = useState(false)
  const [userBots, setUserBots] = useState(null);
  const [loadedBotId, setLoadedBotId] = useState(null);
  const [isBotPublic, setIsBotPublic] = useState(false);

  const handleEditorChange = (value) => {
    setCode(value);
    onChange("code", value);
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => {
    if (!hasUserBots) {
      getUserBotList(host + '/bots/my-bots')
          .then(() => setShowModal(true));
    } else {
      setShowModal(true);
    }
  }

  const onSelectChange = (sl) => {
    setLanguage(sl);
  };

  useEffect(() => {
    if (enterPress && ctrlPress) {
      handleCompile();
    }
  }, [ctrlPress, enterPress]);

  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };

  const handleNew = () => {
    window.location.reload();
  }
  const handleCompile = () => {
    setProcessing(true);
    setOutputDetails(null);

    const formDataCreate = {
      name: botName,
      content: code,
      public: isBotPublic,
      language: "botlang",
      languageVersion: "0.0.1"
    };
    const formDataUpdate = {
      id: loadedBotId,
      name: botName,
      content: code,
      public: isBotPublic,
      language: "botlang",
      languageVersion: "0.0.1"
    };

    const options = {
      method: loadedBotId !== null ? "PUT" : "POST",
      url: host + '/bots/create-or-update',
      data: loadedBotId !== null ? formDataUpdate : formDataCreate,
    };

    axios
      .request(options)
      .then(function (response) {
        setProcessing(false);
      })
      .catch((err) => {
        let error = err.response ? err.response.data : err;
        setOutputDetails(error.error);
        setProcessing(false);
      });
  };

  function getUserBotList(url) {
    const options = {
      method: "GET",
      url: url,
    };

    if (!hasUserBots){
      return axios
          .request(options)
          .then(function (response) {
            let botSelectList = response.data;
            var botsJson = []
            for (var i=0;i<botSelectList.bots.length;i++){
              botsJson[i] = {
                'id': botSelectList.bots[i].id,
                'name': botSelectList.bots[i].name,
                'label': botSelectList.bots[i].name,
                'value': botSelectList.bots[i].id,
                'code': botSelectList.bots[i].content,
                'public': botSelectList.bots[i].public,
              }
            }
            setHasUserBots(true);
            setUserBots(botsJson)
            return botsJson
          })
          .catch((err) => {
            let error = err.response ? err.response.data : err;
            // get error status
            let status = err.response.status;
            console.log("status", status);
            if (status === 429) {
              console.log("too many requests", status);
            }
            console.log("catch block...", error);
            return []
          });
    }
  }

  const navigate = useNavigate();
  const routeToArena = () => navigate('/arena');
  const logout = () => navigate('/logout');

  function handleThemeChange(th) {
    const theme = th;
    if (["light", "vs-dark"].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme.value).then((_) => setTheme(theme));
    }
  }
  useEffect(() => {
    defineTheme("oceanic-next").then((_) =>
      setTheme({ value: "oceanic-next", label: "Oceanic Next" })
    );
  }, []);

  const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  if(localStorage.getItem('access_token') === null){
    window.location.href = '/login'
  }
  else {

    return (
        <>
          <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
          />

          {hasUserBots ? (
              <BotLoadModal
                show={showModal}
                handleClose={handleClose}
                userBots={userBots}
                setLoadedBotId={setLoadedBotId}
                setLoadedBotName={setBotName}
                setLoadedBotCode={setCode}
                setLoadedBotPublic={setIsBotPublic}/>
               ): <div></div>
          }


          <CoreNavBar loggedIn={true}/>
          <Container className="container w-100 me-1 mb-3 border-bottom-2 border-end-2 border-start-2 border-black rounded-bottom-2 shadow-[0px_4px_0px_4px_rgba(0,0,0)] px-4 py-2 bg-white bg-dark-subtle ">
            <div className="flex flex-row">
              <div className="me-auto flex flex-row">
                <div className="px-4 py-2">
                  <label>Language:</label>
                  <LanguagesDropdown onSelectChange={onSelectChange}/>
                </div>
                <div className="px-4 py-2">
                  <label>Editor Theme:</label>
                  <ThemeDropdown handleThemeChange={handleThemeChange} theme={theme}/>
                </div>
                <div className="px-4 py-2">
                  <form>
                    <label> Bot Name:
                      <input
                          value = {botName}
                          disabled={loadedBotId !== null}
                          className="form-control mt-0 rounded-md border-2 z-10 border-black shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2  hover:shadow transition duration-200 bg-white"
                          onChange={e => setBotName(e.target.value)}/>
                    </label>
                  </form>
                </div>
                <div className="px-4 py-2">
                  <div className={"flex flex-row"}>
                    <div className={"col-auto"}>
                      <label>Make Bot Public:</label>
                      <Form>
                        <Form.Check // prettier-ignore
                            type="switch"
                            checked={isBotPublic}
                            onChange={e => setIsBotPublic(e.target.checked)}
                            id="custom-switch"
                            className={"form-switch-md"}
                        />
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="col-md px-4 py-2">
                  <button
                      onClick={handleShow}
                      disabled={false}
                      className={classnames(
                          "mt-4 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0 bg-gradient-to-r from-yellow-500 via-yellow-400 to-orange-300 text-light"
                      )}
                  >
                    {processing ? "Processing..." : "Load"}
                  </button>
                </div>
                <div className="col-md px-4 py-2 ">
                  <button
                      onClick={handleCompile}
                      disabled={!(code && botName)}
                      className={classnames(
                          "mt-4 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0 bg-gradient-to-r from-green-500 via-green-400 to-green-300 text-light",
                          !(code && botName) ? "opacity-50 shadow-[0px_0px_0px_0px_rgba(0,0,0)]" : ""
                      )}
                  >
                    {processing ? "Processing..." : "Save"}
                  </button>
                </div>
                <div className="col-md px-4 py-2 ">
                  <button
                      onClick={handleNew}
                      className={classnames(
                          "mt-4 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0 bg-gradient-to-r from-blue-500 via-blue-400 to-blue-300 text-light"
                      )}
                  >
                    {processing ? "Processing..." : "New"}
                  </button>
                </div>
              </div>

            </div>
          </Container>

          <div className="flex flex-row space-x-4 items-start px-4 py-4">
            <div className="flex flex-col w-full h-full justify-start items-end">
              {!loadedBotId ? (
                  <CodeEditorWindow
                      code={code}
                      onChange={onChange}
                      language={language?.value}
                      theme={theme.value}
                  />
              ) : (
                  <div className="overlay rounded-md overflow-hidden w-full h-full shadow-4xl">
                    <Editor
                        height={"85vh"}
                        width={`100%`}
                        language={language?.value || "javascript"}
                        value={code}
                        theme={theme.value || "oceanic-next"}
                        defaultValue="// some comment"
                        onChange={handleEditorChange}
                    />
                  </div>
              )}

            </div>

            <div className="right-container flex flex-shrink-0 w-[30%] flex-col">
              <OutputWindow outputDetails={outputDetails} title={"Compile Output"}/>
              <div className="flex flex-col items-end mt-1">
                <Card >
                  <Card.Body style={{
                    maxHeight: 'calc(77vh - 210px)',
                    maxWidth: 'calc(29vw)',
                    overflowX: 'auto',
                    overflowY: 'auto'
                  }}>
                    <DocumentationPage myMarkdownFile={require("../documentation/botbuilder/botbuilderGuide.md")}/>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </>
    );
  }
};
export default BotBuilder;
