import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {classnames} from "../../utils/general";
import {Trash} from "react-bootstrap-icons";


const ErrorModal = ({errorText, handleClose, show}) => {
    return <div
        className="modal show"
        style={{display: 'block', position: 'initial'}}
    >
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errorText}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
}

export default ErrorModal