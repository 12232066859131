import React from "react";
import classNames from "classnames";

const OutputWindow = ({ title, outputDetails, fullHeight }) => {
  const height = fullHeight ? "h-100" : "h-56"
  return (
    <>
      <h1 className="font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-slate-900 to-slate-700 mb-2 text-dark">
          {title}
      </h1>
      <div className={classNames("w-full bg-[#1e293b] rounded-md text-white font-normal text-sm overflow-y-auto", height)}>
        {outputDetails ? <>{
          <pre className="px-2 py-1 font-normal text-xs text-green-500">
          {outputDetails}
        </pre>
        }</> : null}
      </div>
    </>
  );
};

export default OutputWindow;
