import Dropdown from 'react-bootstrap/Dropdown';
import Nav from "react-bootstrap/Nav";
import {NavDropdown} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {MDBBadge, MDBIcon} from "mdb-react-ui-kit";
import hostName from "../../constants/hosts";
import axios from "axios";
import {useState} from "react";

const ProfileDropdown = () => {
    const navigate = useNavigate();
    const logout = () => navigate('/logout');
    const profile = () => navigate('/profile');
    const requests = () => navigate('/requests');
    const support = () => navigate('/support');
    const roadmap = () => navigate('/roadmap');
    const [hasFetchedFriendRequestsCount, setHasFetchedFriendRequestsCount] = useState(false);

    const [friendRequestsCount, setFriendRequestsCount] = useState(getUserFriendRequests());
    const [hasFriendRequestsCount, setHasFriendRequestsCount] = useState(false);

    function getUserFriendRequests() {
        const options = {
            method: "GET",
            url: hostName + "/friends/requests/count",
        };

        if (!hasFetchedFriendRequestsCount) {
            return axios
                .request(options)
                .then(function (response) {
                    let friendRequestCount = response.data.count;

                    setFriendRequestsCount(friendRequestCount);
                    setHasFetchedFriendRequestsCount(true);
                    if (friendRequestCount > 0){
                        setHasFriendRequestsCount(true);
                    }
                    return friendRequestCount;
                })
                .catch((err) => {
                    let error = err.response ? err.response.data : err;
                    // get error status
                    let status = err.response.status;
                    console.log("status", status);
                    if (status === 429) {
                        console.log("too many requests", status);
                    }
                    console.log("catch block...", error);
                    return []
                });
        }
    }

    return (
        <Nav>
            <NavDropdown
                         align="end"
                         title={
                             <div className="pull-left">
                                 <img className="rounded-circle d-inline-block align-top"
                                      src={"./images/bot1.png"}
                                      alt="user pic"
                                      width="35"
                                      height="35"
                                 />
                                 {hasFriendRequestsCount ? (
                                     <MDBBadge color='danger' notification pill className={"position-absolute translate-middle"}>
                                         {friendRequestsCount}
                                     </MDBBadge>
                                 ): (
                                     <div></div>
                                 )}

                             </div>

                         }
                         id="basic-nav-dropdown">

                <NavDropdown.Item onClick={profile}>Profile</NavDropdown.Item>
                <NavDropdown.Item onClick={requests}>
                    Requests
                    {hasFriendRequestsCount ? (
                        <MDBBadge color='danger' notification pill className={"mx-2"}>
                            {friendRequestsCount}
                        </MDBBadge>
                    ): (
                        <div></div>
                    )}

                </NavDropdown.Item>
                <NavDropdown.Item onClick={support}>Report an issue</NavDropdown.Item>
                <NavDropdown.Item onClick={roadmap}>Roadmap</NavDropdown.Item>
                <NavDropdown.Divider />
                <Nav>
                    <Nav.Link onClick={logout}>
                        Logout
                    </Nav.Link>
                </Nav>
            </NavDropdown>
        </Nav>
    );
}

export default ProfileDropdown;