import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {classnames} from "../../utils/general";
import {useState} from "react";
import { Trash } from 'react-bootstrap-icons';
import axios from "axios";
import hostName from "../../constants/hosts";

const BotLoadModal = ({show, handleClose, userBots, setLoadedBotId, setLoadedBotName, setLoadedBotCode, setLoadedBotPublic}) => {
    const [selectedBotId, setSelectedBotId] = useState(null);
    const [selectedBotName, setSelectedBotName] = useState(null);
    const [selectedBotCode, setSelectedBotCode] = useState(null);
    const [selectedBotPublic, setSelectedBotPublic] = useState(null);
    const host = hostName

    const handleDelete = (id) => {
        const formDataDelete = {
            id: id,
        };

        const options = {
            method: "DELETE",
            url: host + '/bots/create-or-update',
            data: formDataDelete,
        };

        axios
            .request(options)
            .then(function () {
                window.location.reload();
            })

    };
    const handleLoad = () => {
        if (!selectedBotId) {
            handleClose();
        } else {
            setLoadedBotId(selectedBotId);
            setLoadedBotName(selectedBotName);
            setLoadedBotCode(selectedBotCode);
            setLoadedBotPublic(selectedBotPublic);
            handleClose();
        }
    }
    const selectBot = (bot) => {
        setSelectedBotId(bot.id);
        setSelectedBotName(bot.name);
        setSelectedBotCode(bot.code);
        setSelectedBotPublic(bot.public);
    }

    return (

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>My Bots</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto'
                }}>
                    {userBots.map((row, i) => (
                        <div key={i} className={("flex flex-row mt-1 mb-1 ")}>
                            <Button
                                onClick={() => selectBot(row)}
                                variant={row.id === selectedBotId ? "info" : "outline-info"}
                                className={classnames("w-100 text-dark hover:text-white")
                            }>
                                 {row.name}
                            </Button>
                            <Button variant={"danger"} className={"ml-1"} onClick={() => handleDelete(row.id)}>
                                <Trash/>
                            </Button>
                        </div>
                        ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleLoad}>Load</Button>
                </Modal.Footer>
            </Modal>

    );
}

export default BotLoadModal;