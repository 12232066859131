import axios from "axios";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import CoreNavBar from "../nav/CoreNavBar";
import hostName from "../../constants/hosts";
export const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [err, setErr] = useState(null);
    const navigate = useNavigate();
    const signup = () => navigate('/signup');
    const host = hostName

    const submit = async e => {
        e.preventDefault();
        setErr(null);
        const user = {
            username: username,
            password: password
        };

        const {dat} =  await axios.post(host + '/token/', user,{headers: {
                'Content-Type': 'application/json'
            }}, {withCredentials: true})
            .then(function (response) {
                let data = response.data
                localStorage.clear();
                localStorage.setItem('access_token', data.access);
                localStorage.setItem('refresh_token', data.refresh);
                axios.defaults.headers.common['Authorization'] =
                    `Bearer ${data.access}`;
                window.location.href = '/'
            }) .catch((err) => {


                if (err.response.status >= 400 && err.response.status < 500) {
                    setErr("No active accounts with those credentials.");
                } else {
                    let error = err.response ? err.response.data : err;
                    setErr(error);
                }

            });
        console.log("errp", dat)
    }
return(
    <>
        <CoreNavBar loggedIn={false}/>
        <div
            className="Auth-form-container  bg-gradient-to-r from-green-500 via-green-400 to-blue-300 text-light p-5 vh-100">
            <form className="Auth-form" onSubmit={submit}>
                <div className="Auth-form-content">
                    <h3 className="Auth-form-title">Sign In</h3>
                    <div className="form-group mt-3">
                        <label>Username: </label>
                        <input className="form-control mt-1"
                               placeholder="Enter Username"
                               name='username'
                               type='text' value={username}
                               required
                               onChange={e => setUsername(e.target.value)}/>
                    </div>
                    <div className="form-group mt-3">
                        <label>Password: </label>
                        <input name='password'
                               type="password"
                               className="form-control mt-1"
                               placeholder="Enter password"
                               value={password}
                               required
                               onChange={e => setPassword(e.target.value)}/>
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <div className="flex flex-row">
                            <button type="submit"
                                    className="btn btn-primary px-4 py-2">Submit
                            </button>
                            <button className="btn btn-primary px-4 py-2 mx-2" onClick={signup}>
                                Sign up
                            </button>
                        </div>
                    </div>
                    <div>
                        {err ?
                            (<p className={"text-red-600"}>{err}</p> ):
                            ( <></>)
                        }
                    </div>
                </div>
            </form>
        </div>
    </>

)
}
