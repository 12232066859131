import CoreNavBar from "../nav/CoreNavBar";
import {MDBContainer} from "mdb-react-ui-kit";
import {Check} from "react-bootstrap-icons";



const Roadmap = () => {

    return (
        <>
            <CoreNavBar loggedIn={localStorage.getItem('access_token') !== null}/>
            <div className={"bg-gradient-to-r from-green-500 via-green-400 to-blue-300 text-light px-5 py-0 h-100"}>
                <MDBContainer className="py-5">
                    <ul className="timeline-with-icons">
                        <li className="timeline-item mb-5">
                            <span className="timeline-icon-completed">
                                <Check color={"white"}/>
                            </span>

                            <h5 className="fw-bold">Beta Launch</h5>
                            <p className="text-muted mb-2 fw-bold">Jan 2024</p>
                            <p className="text-muted">
                                Bitbots launches with a preliminary version of Botlang, arenas, friends,
                                and a simple leaderboard.
                            </p>
                        </li>

                        <li className="timeline-item mb-5">
                            <span className="timeline-icon">

                            </span>
                            <h5 className="fw-bold">Tutorial series</h5>
                            <p className="text-muted mb-2 fw-bold">Feb 2024</p>
                            <p className="text-muted">
                                Users will be able to play and learn through a series of tutorials that
                                progressively become more challenging, in order to teach the basics of programming.
                            </p>
                        </li>

                        <li className="timeline-item mb-5">
                            <span className="timeline-icon">

                            </span>
                            <h5 className="fw-bold">Brackets and tournaments</h5>
                            <p className="text-muted mb-2 fw-bold">TBD</p>
                            <p className="text-muted">
                                Users will be able to create tournament brackets, which they can invite friends to participate in
                                via a link or code.
                            </p>
                        </li>

                        <li className="timeline-item mb-5">
                            <span className="timeline-icon">

                            </span>
                            <h5 className="fw-bold">Global leaderboard</h5>
                            <p className="text-muted mb-2 fw-bold">TBD</p>
                            <p className="text-muted">
                                Rank your bots based on tiers, compared to their peers. Participate in ranked match-making
                                to test your bot building prowess. This will prep us for live battles.
                            </p>
                        </li>

                        <li className="timeline-item mb-5">
                            <span className="timeline-icon">

                            </span>
                            <h5 className="fw-bold">Live battles</h5>
                            <p className="text-muted mb-2 fw-bold">TBD</p>
                            <p className="text-muted">
                                Users will be able to initialize "live" battles with their friends, or in match-making mode,
                                which will allow them to spend points to dynamically upgrade/change their bots behavior,
                                sabotage enemy bots, or otherwise impact the game via their actions.
                            </p>
                        </li>
                        <li className="timeline-item mb-5">
                            <span className="timeline-icon">

                            </span>
                            <h5 className="fw-bold">Mana Upgrades</h5>
                            <p className="text-muted mb-2 fw-bold">TBD</p>
                            <p className="text-muted">
                                Mana will add more possibilities to bot actions, like making ranged bots, dropping mines,
                                increasing action count per round, and boosting attack damage.
                            </p>
                        </li>
                        <li className="timeline-item mb-5">
                            <span className="timeline-icon">

                            </span>
                            <h5 className="fw-bold">Achievements and cosmetics</h5>
                            <p className="text-muted mb-2 fw-bold">TBD</p>
                            <p className="text-muted">
                                Users will be able to customize their bots (for free) with a variety of designs and cosmetic options.
                                Achievements will also be awarded to users for reaching milestones.
                            </p>
                        </li>
                        <li className="timeline-item mb-5">
                            <span className="timeline-icon">

                            </span>
                            <h5 className="fw-bold">Match types and challenges expand</h5>
                            <p className="text-muted mb-2 fw-bold">TBD</p>
                            <p className="text-muted">
                                Fight in bullet (mini) or 4 person matches. Also considering a global arena, where bots
                                can persist/fight globally against hundreds or thousands of opponents.
                            </p>
                        </li>
                        <li className="timeline-item mb-5">
                            <span className="timeline-icon">

                            </span>
                            <h5 className="fw-bold">Languages Added</h5>
                            <p className="text-muted mb-2 fw-bold">TBD</p>
                            <p className="text-muted">
                                We plan to add support for major languages, as well as BotLang - including Python, Java,
                                Javascript, C++, C, and more.
                            </p>
                        </li>
                    </ul>
                </MDBContainer>
            </div>

        </>

    );
}

export default Roadmap;