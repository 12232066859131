import axios from "axios";
import hostName from "../constants/hosts";
let refresh = false;
axios.interceptors.response.use(function (response) {
    // if 2XX this is used
    return response;
}, async function (error) {
    // anything other than 2XX is handled here.
    const host = hostName

    if (error.response.status === 401 && !refresh) {
        refresh = true;
        const response = await axios.post(
            host + '/token/refresh/',
            {refresh:localStorage.getItem('refresh_token')},
            { headers: {'Content-Type': 'application/json'}},
            {withCredentials: true}
        );

        if (response.status === 200) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            return axios(error.config);
        }
        refresh = false;
    }
    return Promise.reject(error);
});


axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('access_token');
    if (token) {
        axios.defaults.headers.common['Authorization'] = token;
        config.headers['Authorization'] = "Bearer " + token;
    } else {
        axios.defaults.headers.common['Authorization'] = null;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
