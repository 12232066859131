import {Button} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {PersonAdd} from "react-bootstrap-icons";
import axios from "axios";
import React, {useState} from "react";
import hostName from "../../constants/hosts";


const FindFriendsModal = ({show, handleClose} ) => {
    const [searchField, setSearchField] = useState("")
    const [users, setUsers] = useState([])
    const [selectedUserId, setSelectedUserId] = useState(null);

    const handleSearchAction = (query) => {
        if (query !== "") {
            setSearchField(query);
            searchRequest(query);
        } else {
            setSearchField("");
            setUsers([]);
        }
    }
    const searchRequest = (query) => {
        const options = {
            method: "GET",
            url: hostName + '/users/search/?username=' + query,
        };

        axios
            .request(options)
            .then(function (response) {
                let users = response.data;
                var usersJson = []
                for (var i=0;i<users.users.length;i++){
                    usersJson[i] = {
                        'id': users.users[i].id,
                        'username': users.users[i].username,
                        'friends': users.users[i].friends,
                        'requested': users.users[i].requested
                    }
                }

                console.log(usersJson)
                setUsers(usersJson);
                return usersJson
            })
    };

    const getButtonVariant = (userRequested, userFriend) => {
        if (userFriend) {
            return "success"
        } else if (userRequested) {
            return "secondary"
        } else {
            return "primary"
        }
    }

    const requestFriend = (userId) => {
        const options = {
            method: "POST",
            url: hostName + '/friends/'+ userId +'/create',
        };

        axios
            .request(options)
            .then(function (response) {
                handleSearchAction(searchField);
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                console.log(error);
            });
    };


    return (
        <div
            className="modal show"
            style={{ display: 'block', position: 'initial' }}
        >
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Search users</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"row flex-row"}>

                        <form className={"col-auto"}>
                            <label>
                                <input
                                    placeholder={"Username"}
                                    className="form-control mt-0 rounded-md border-2 z-10 border-black shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2  hover:shadow transition duration-200 bg-white"
                                    onChange={e => handleSearchAction(e.target.value)}/>
                            </label>
                        </form>

                    </div>
                    <div className={"m-1"}>
                        { users.length !== 0 ? (

                        <ul className={"list-group"}>
                            {users.map((row, i) => (
                                <li className={"list-item p-2 hover:bg-green-300"}>
                                    <div key={i} className={("flex flex-row mt-1 mb-1")}>
                                        <div className={"w-100"}>
                                            {row.username}

                                        </div>
                                        <Button variant={getButtonVariant(row.requested, row.friends)} className={"ml-1"}
                                                disabled={(row.friends || row.requested)}
                                                onClick={() => requestFriend(row.id)}>
                                            <PersonAdd/>
                                        </Button>
                                    </div>
                                </li>
                            ))}

                        </ul>
                        ) : (
                        <div className={"m-4"}>
                            <h5>Search for your friends by their username!</h5>
                        </div>
                        )}

                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}


export default FindFriendsModal;