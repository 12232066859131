import CoreNavBar from "../nav/CoreNavBar";
import React, {useState} from "react";
import axios from "axios";
import hostName from "../../constants/hosts";
import {Button, Card} from "react-bootstrap";
import {ChevronRight} from "react-bootstrap-icons";

const RequestsPage = () => {
    const [hasFetchedFriendRequests, setHasFetchedFriendRequests] = useState(false);
    const [friendRequests, setFriendRequests] = useState(getUserFriendRequests());
    const [hasFriendRequests, setHasFriendRequests] = useState(false);
    const testFriendsJson = {
        'id': "id",
        'username': "test",
        'created': "test",
    }


    const acceptFriend = (userId) => {
        const options = {
            method: "POST",
            url: hostName + '/friends/'+ userId +'/accept',
        };

        axios
            .request(options)
            .then(function (response) {
                window.location.reload();
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                console.log(error);
            });
    };

    const rejectFriend = (userId) => {
        const options = {
            method: "POST",
            url: hostName + '/friends/'+ userId +'/reject',
        };

        axios
            .request(options)
            .then(function (response) {
                window.location.reload();
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                console.log(error);
            });
    };

    const FriendRequestList = () => {
        if (hasFriendRequests) {
            return <ul className={"list-group"}>
                {friendRequests.map((row, i) => (
                    <li className={"list-item"} key={i}>
                        <Card className={"p-2 mb-2"}>
                            <div className={"row flex-row m-2"}>
                                <p className={"p-0"}><b>{row.username}</b> has requested to add you as a friend!</p>
                            </div>
                            <div className={"row flex-row m-2"}>
                                <div className={"col-auto p-0"}>
                                    <button onClick={() => rejectFriend(row.id)}
                                        className={"border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0 bg-gradient-to-r from-red-500 via-red-400 to-red-300 text-light "}>
                                        Decline
                                    </button>
                                </div>
                                <div className={"col-auto"}>
                                    <button
                                        onClick={() => acceptFriend(row.id)}
                                        className={"border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0 bg-gradient-to-r from-green-500 via-green-400 to-green-300 text-light"}>
                                        Accept
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </li>
                ))}
            </ul>
        } else {
            return <div></div>
        }
    }

    function getUserFriendRequests() {
        const options = {
            method: "GET",
            url: hostName + "/friends/requests",
        };

        if (!hasFetchedFriendRequests) {
            return axios
                .request(options)
                .then(function (response) {
                    let friendRequestList = response.data;
                    var friendsJson = []
                    for (var i=0;i<friendRequestList.friendRequests.length;i++){
                        friendsJson[i] = {
                            'id': friendRequestList.friendRequests[i].id,
                            'username': friendRequestList.friendRequests[i].username,
                            'created': friendRequestList.friendRequests[i].created,
                        }
                    }
                    //
                    // for (var j = friendsJson.length+1; j<25; j++){
                    //     friendsJson[j] = testFriendsJson
                    // }


                    setFriendRequests(friendsJson);
                    setHasFetchedFriendRequests(true);
                    if (friendsJson.length > 0) {
                        setHasFriendRequests(true);
                    }
                    return friendsJson
                })
                .catch((err) => {
                    let error = err.response ? err.response.data : err;
                    // get error status
                    let status = err.response.status;
                    console.log("status", status);
                    if (status === 429) {
                        console.log("too many requests", status);
                    }
                    console.log("catch block...", error);
                    return []
                });
        }
    }


    return (
        <>
            <CoreNavBar loggedIn={localStorage.getItem('access_token') !== null}/>
            <div className={"row flex-row m-4 inbox"}>
                <div className={"row flex-row h-100"}>
                    <div className={"col-4"}>
                        <button className={"bg-blue-500 w-100 rounded-sm h-10 shadow-[0px_0px_0px_3px_rgba(0,0,0)] mt-1"}>
                            <div className={"flex flex-row align-items-center justify-items-center"}>
                                <div className="me-auto flex flex-row pl-2 text-white ">
                                    Friends
                                </div>
                                <div className="flex flex-row">
                                    <ChevronRight color={"white"} className={"mr-3"}/>
                                </div>
                            </div>
                        </button>
                        <button disabled={true} className={"bg-gray-300 mt-3 w-100 rounded-sm h-10 shadow-[0px_0px_0px_3px_rgba(150,150,150)] mt-1"}>
                            <div className={"flex flex-row align-items-center justify-items-center"}>
                                <div className="me-auto flex flex-row pl-2 text-gray-500 ">
                                    Games
                                </div>
                                <div className="flex flex-row">
                                    <ChevronRight color={"black"} className={"mr-3"}/>
                                </div>
                            </div>
                        </button>
                        <button disabled={true} className={"bg-gray-300 mt-3 w-100 rounded-sm h-10 shadow-[0px_0px_0px_3px_rgba(150,150,150)] mt-1"}>
                            <div className={"flex flex-row align-items-center justify-items-center"}>
                                <div className="me-auto flex flex-row pl-2 text-gray-500 ">
                                    Tournaments
                                </div>
                                <div className="flex flex-row">
                                    <ChevronRight color={"black"} className={"mr-3"}/>
                                </div>
                            </div>
                        </button>
                    </div>
                    <div className={"col-8"}>
                        <Card className={"p-3 h-100 shadowed-card green-background"}>
                            <Card.Title className={"text-white"}>
                                <p>Friend Requests</p>
                                <hr/>
                            </Card.Title>
                            <FriendRequestList/>
                        </Card>
                    </div>
                </div>

            </div>
        </>
    );
}

export default RequestsPage