import {Col, Container, Row} from "react-grid-system";
import React, {useState} from "react";
import gsap from 'gsap'
import {Draggable} from 'gsap/Draggable.js'
import Flip from 'gsap/Flip.js'

const TestArena = () => {
    var myArr = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    function initMatrix() {
        var arr = [];
        for (var i = 0; i < 21; i++) {
            arr[i] = Array.from(myArr);
        }
        return arr;
    }
    function getPic(allegiance, facingDirection) {
        let style = {
            transform: ''
        };
        let stringified = JSON.stringify(facingDirection)

        if (stringified === JSON.stringify([-1, 0])){
            style = {
                transform: 'rotate(180deg)',
            }
        }  else if (stringified === JSON.stringify([0, -1])){
            style = {
                transform: 'rotate(90deg)',
            }
        }else if (stringified === JSON.stringify([0, 1])){
            style = {
                transform: 'rotate(270deg)',
            }
        }

        if (allegiance === 1) {
            return <img class="card" data-flip-id="rememberMe"  style={style} src={"./images/bot1.png"} />
        } else if (allegiance === 2) {
            return <img style={style} src={"./images/bot2.png"} />
        } else {
            return <img style={style} src={"./images/blank.png"} />
        }
    }

    gsap.registerPlugin(Flip);

    const card = document.querySelector(".card");
    const leftHand = document.querySelector(".left");
    const rightHand = document.querySelector(".right");
    let left = true;

    document.body.addEventListener("click", () => {
        // grab the state of the outgoing card
        let state = Flip.getState(".card");

        // do your DOM restructuring
        deleteAndReplace();

        // FLIP
        Flip.from(state, {
            duration: 1,
            ease: "power1.inOut",
            targets: ".card" // important to add so that Flip looks for the new target elements after they're added to the DOM
        });
    });
    // like a lot of frameworks do 🙃
    function deleteAndReplace(id, nextImage) {
        let newParent;
        let oldCard = document.querySelector(".card");

        oldCard.remove();

        left ? (newParent = rightHand) : (newParent = leftHand);

        newParent.insertAdjacentHTML(
            "afterbegin",
            '<img class="card" data-flip-id="rememberMe" src="https://assets.codepen.io/16327/card.svg" />'
        );

        // data-flip-id="rememberme"

        left = !left;
    }

    const [arenaMatrix, setMatrix] = useState(initMatrix);

    return (
        <>


            {/*<div className="flex flex-row">*/}
            {/*    <div className={"col-11"}>*/}
            {/*        <Container className="border-2 border-black rounded-md bg-light mx-0">*/}
            {/*            {arenaMatrix.map((row, i) => (*/}
            {/*                <Row className="border-top border-bottom py-1" key={i}>*/}
            {/*                    {row.map((col, j) => (*/}
            {/*                        <Col className="border-end border-start" key={j}>*/}
            {/*                            {(i === 1 && j === 1) ? getPic(1, [-1, 0]): getPic(col.allegiance, col.direction)}*/}
            {/*                        </Col>*/}
            {/*                    ))}*/}
            {/*                </Row>*/}
            {/*            ))}*/}
            {/*        </Container>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    )
}

export default TestArena