import CoreNavBar from "../nav/CoreNavBar";
import {Button, Card, Spinner} from "react-bootstrap";
import classNames from "classnames";
import {TrophyFill} from "react-bootstrap-icons";
import React, {useState} from "react";
import {classnames} from "../../utils/general";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import hostName from "../../constants/hosts";
import BotDropdown from "../arena/BotDropdown";
import ErrorModal from "./ErrorModal";
import RankingModal from "./RankingModal";

const LeaderboardPage = () => {
    const host = hostName

    const navigate = useNavigate();

    const loggedIn = localStorage.getItem('access_token') !== null;
    const [hasUserBots, setHasUserBots] = useState(false);
    const [userBots, setUserBots] = useState(getUserBotList(host + '/bots/my-bots'));
    const [hasLeaderBots, setHasLeaderBots] = useState(false);
    const [leaders, setLeaders] = useState(getLeaders(host + '/leaderboard/get'));
    const [hasMyLeaderBots, setHasMyLeaderBots] = useState(false);
    const [myLeaders, setMyLeaders] = useState(getMyLeaders(host + '/leaderboard/get/my-leaderboard'));
    const [selectedBot, setSelectedBot] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorModalText, setErrorModalText] = useState("Error");
    const [rankingModal, setRankingModal] = useState(false);
    const [ranked, setRanked] = useState(false);
    const [rank, setRank] = useState(null);
    const paginationEnabled = false;

    const onSelectChange = (sb) => {
        setSelectedBot(sb);
    };

    const build = () => navigate('/build');
    function getUserBotList(url) {
        const options = {
            method: "GET",
            url: url,
        };

        if (!hasUserBots){
            return axios
                .request(options)
                .then(function (response) {
                    let botSelectList = response.data;
                    var botsJson = []
                    for (var i=0;i<botSelectList.bots.length;i++){
                        botsJson[i] = {
                            'id': botSelectList.bots[i].id,
                            'name': botSelectList.bots[i].name,
                            'label': botSelectList.bots[i].name,
                            'value': botSelectList.bots[i].id,
                        }
                    }
                    setHasUserBots(true);
                    setUserBots(botsJson)
                    return botsJson
                })
                .catch((err) => {
                    let error = err.response ? err.response.data : err;
                    // get error status
                    let status = err.response.status;
                    console.log("status", status);
                    if (status === 429) {
                        console.log("too many requests", status);
                    }
                    console.log("catch block...", error);
                    return []
                });
        }
    }
    function getLeaders(url) {
        const options = {
            method: "GET",
            url: url,
        };

        if (!hasLeaderBots){
            return axios
                .request(options)
                .then(function (response) {
                    let botSelectList = response.data;
                    var botsJson = []
                    for (var i=0;i<botSelectList.bots.length;i++){
                        botsJson[i] = {
                            'id': botSelectList.bots[i].id,
                            'name': botSelectList.bots[i].name,
                            'user': botSelectList.bots[i].user,
                            'rank': botSelectList.bots[i].rank,
                        }
                    }
                    setHasLeaderBots(true);
                    setLeaders(botsJson)
                    return botsJson
                })
                .catch((err) => {
                    let error = err.response ? err.response.data : err;
                    // get error status
                    let status = err.response.status;
                    console.log("status", status);
                    if (status === 429) {
                        console.log("too many requests", status);
                    }
                    console.log("catch block...", error);
                    return []
                });
        }
    }

    function getMyLeaders(url) {
        const options = {
            method: "GET",
            url: url,
        };

        if (!hasMyLeaderBots){
            return axios
                .request(options)
                .then(function (response) {
                    let botSelectList = response.data;
                    var botsJson = []
                    for (var i=0;i<botSelectList.bots.length;i++){
                        botsJson[i] = {
                            'id': botSelectList.bots[i].id,
                            'name': botSelectList.bots[i].name,
                            'user': botSelectList.bots[i].user,
                            'rank': botSelectList.bots[i].rank,
                        }
                    }
                    setHasMyLeaderBots(true);
                    setMyLeaders(botsJson)
                    return botsJson
                })
                .catch((err) => {
                    let error = err.response ? err.response.data : err;
                    // get error status
                    let status = err.response.status;
                    console.log("status", status);
                    if (status === 429) {
                        console.log("too many requests", status);
                    }
                    console.log("catch block...", error);
                    return []
                });
        }
    }

    const sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };

    async function handleSubmit(id){
        const formDataDelete = {
            id: id,
        };

        const options = {
            method: "POST",
            url: host + '/leaderboard/bot/' + id + '/submit',
            data: formDataDelete,
        };


        setLoading(true);
        let res = await axios
            .request(options)
            .then(function (resp)  {
                let data = resp.data
                setRanked(data.rank !== null);
                setRankingModal(true);
                setRank(data.rank);
                setLoading(false);

            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                // get error status

                let status = err.response.status;
                console.log("status", status);
                if (status === 429) {
                    console.log("too many requests", status);
                }
                if (status === 409) {
                    setErrorModal(true);
                    setErrorModalText("This bot is already ranked!")
                }
                setLoading(false);
                console.log("catch block...", error);
                return []
            })
    }


    if(localStorage.getItem('access_token') === null){
        window.location.href = '/login'
    }
    else {
        return (
            <>
                <CoreNavBar loggedIn={loggedIn}/>
                <ErrorModal handleClose={()=> setErrorModal(false)} show={errorModal} errorText={errorModalText}/>
                <RankingModal handleClose={() => setRankingModal(false)} show={rankingModal} ranked={ranked} rank={rank}/>
                <div
                    className={"flex flex-row vh-100 p-5 w-100 bg-gradient-to-r from-green-500 via-green-400 to-blue-300 "}>
                    <div className={"col-5 h-100 pb-5"}>
                        <Card className={"h-100"}>
                            <Card.Header>
                                Top 25 Leaderboard
                            </Card.Header>
                            <Card.Body className={"pb-0 pt-0 px-0 h-100 overflow-scroll"}>
                                <div className={"h-100"}>
                                    {hasLeaderBots ? (
                                        <>
                                            {leaders.map((row, i) => (
                                                <div
                                                    className={classNames("px-4", row.rank === 1 ? "hover:bg-amber-400" : "hover:bg-green-300 ")}
                                                    key={i}>
                                                    <li className={classNames("flex flex-row pb-3", i === 0 ? "pt-4" : "pt-3")}>
                                                        <div
                                                            className={"me-auto flex flex-row justify-items-center align-items-center"}>
                                                            {row.rank === 1 ? (
                                                                <TrophyFill color={"gold"}/>
                                                            ) : (
                                                                <>{row.rank}</>
                                                            )}
                                                            <div className={"ml-3"}>{row.name}</div>

                                                        </div>
                                                        <div
                                                            className={"flex flex-row justify-items-center align-items-center"}>
                                                            <div className={"col-auto"}>{row.user}</div>


                                                        </div>
                                                    </li>
                                                    <hr className={"my-0"}/>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className={"col-7 h-100"}>
                        <div
                            className={"flex flex-row align-content-center align-items-center justify-items-center justify-content-center"}>
                            <h1 className={"text-light"}>Submit your bot</h1>
                        </div>
                        <div
                            className={"flex flex-row align-content-center align-items-center justify-items-center justify-content-center mb-4"}>
                            {hasUserBots ? (
                                <div
                                    className={"mt-5 flex flex-row w-100 align-items-center justify-items-center justify-content-center"}>
                                    <div
                                        className={"col-lg w-100 align-items-center justify-items-center justify-content-center"}>
                                        <div
                                            className={"flex flex-row align-items-center justify-items-center justify-content-center"}>
                                            <BotDropdown onSelectChange={onSelectChange} botList={userBots}
                                                         placeholder={"Select your bot"}/>
                                        </div>
                                        <div
                                            className={"mt-5 flex flex-row align-items-center justify-items-center justify-content-center"}>
                                            {loading ? (
                                                <Spinner animation="border" variant={"light"}/>
                                            ) : (
                                                <button
                                                    onClick={() => handleSubmit(selectedBot.id)}
                                                    disabled={selectedBot === null}
                                                    className={classnames("-z-0", selectedBot === null ? " border-2 border-black z-10 px-4 py-2 rounded-md bg-white flex-shrink-0 bg-gradient-to-r from-gray-500 via-gray-500 to-gray-500 text-gray-400" :
                                                        " border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0 bg-gradient-to-r from-amber-500 via-amber-400 to-amber-300 text-light"
                                                    )}
                                                >Submit Bot</button>
                                            )}
                                        </div>
                                    </div>


                                </div>
                            ) : (
                                <>
                                    <button
                                        onClick={build}
                                        disabled={false}
                                        className={classnames(
                                            "mt-4 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0 bg-gradient-to-r from-red-500 via-red-400 to-red-400 text-light"
                                        )}
                                    >Create a bot to join the leaderboard
                                    </button>
                                </>
                            )}
                        </div>
                        <div className={" h-50 w-100 px-5"}>
                            <Card className={"w-100 h-100"}>
                                <Card.Header>
                                    My ranked bots
                                </Card.Header>
                                <Card.Body className={"pb-0 pt-0 px-0 h-100 overflow-scroll"}>
                                    <div className={" h-100"}>
                                        {hasMyLeaderBots ? (
                                            <>
                                                {myLeaders.map((row, i) => (
                                                    <div
                                                        className={classNames("px-4", row.rank === 1 ? "hover:bg-amber-400" : "hover:bg-green-300 ")}
                                                        key={i}>
                                                        <li className={classNames("flex flex-row pb-3", i === 0 ? "pt-4" : "pt-3")}>
                                                            <div
                                                                className={"me-auto flex flex-row justify-items-center align-items-center"}>
                                                                {row.rank === 1 ? (
                                                                    <TrophyFill color={"gold"}/>
                                                                ) : (
                                                                    <>{row.rank === 15000 ? (
                                                                        <>Unranked</>
                                                                    ) : (
                                                                        <>{row.rank}</>
                                                                    )}</>
                                                                )}

                                                            </div>
                                                            <div
                                                                className={"flex flex-row justify-items-center align-items-center"}>
                                                                <div className={"col-auto"}>{row.name}</div>


                                                            </div>
                                                        </li>
                                                        <hr className={"my-0"}/>
                                                    </div>


                                                ))}
                                            </>
                                        ) : (
                                            <></>
                                        )}

                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default LeaderboardPage;