import Modal from "react-bootstrap/Modal";
import ConfettiExplosion from "react-confetti-explosion";
import {Button} from "react-bootstrap";
import React from "react";

const RankingModal = ({show, handleClose, ranked, rank}) => {

    return (
        <Modal show={show} onHide={handleClose} backdrop={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {ranked ? (
                        <>Rank: {rank}!</>
                    ) : (
                        <>Ranking complete!</>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {ranked ? (
                    <>Your bot made the top 25! That's awesome! Go forth and destroy your enemies with your engineering prowess!</>
                ) : (
                    <>Your bot is Unranked! This isn't the end, though! We plan to implement a tier based ranking system, beyond the top 25, to better display the relative strength of your bots. In the meantime, keep it up, you're doing great!</>
                )}
                <div className={'cent'}>
                    {ranked && <ConfettiExplosion force={0.8} duration={3000} particleCount={250} width={1600} />}
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default RankingModal