import CoreNavBar from "../nav/CoreNavBar";
import {Accordion, Button} from "react-bootstrap";
import {classnames} from "../../utils/general";
import React, {useEffect, useState} from "react";
import { ChevronRight } from 'react-bootstrap-icons';
import DocumentationPage from "./DocumentationPage";
import {
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";

const DocumentationHub = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const [selectedElement, setSelectedElement] = useState((location.hash !== null && location.hash !== "") ? location.hash.split('#')[1] : "introduction");

    useEffect(() => {
        // Update the document title using the browser API
        if (location.hash.trim() !== null && location.hash !== "") {
            setSelectedElement(location.hash.split('#')[1])
        } else {
            setSelectedElement("introduction")
        }
    });


    const handleSelect = (element) => {
        const el= element;
        setSelectedElement(el);
        navigate("#"+el);
    };

    const DocsCategory = ({sectionHeader, eventKey, identifiers, displayTexts}) => {
        return (
            <Accordion.Item eventKey={eventKey} className="border-0">
                <Accordion.Header>{sectionHeader}</Accordion.Header>
                <Accordion.Body className="p-0">
                        {identifiers.map((row, i) => (
                            <DocsItem identifier={identifiers[i]} displayText={displayTexts[i]}/>
                        ))}
                </Accordion.Body>
            </Accordion.Item>
        );
    }

    const DocsItem = ({identifier, displayText}) => {
        return (
            <div className={("flex flex-row mt-1 mb-1")}>
                <Button className={("w-100 bg-white border-0 text-dark")}>
                    <button
                        onClick={() => handleSelect(identifier)}
                        className={classnames("w-100 hover:underline", (identifier === selectedElement) ? "underline" : "")}
                    >
                        <div className="flex flex-row">
                            <div className="me-auto flex flex-row pl-2">
                                {displayText}
                            </div>
                            <div className="flex flex-row">
                                <ChevronRight/>
                            </div>
                        </div>
                    </button>
                </Button>
            </div>
        );
    }

    const pages = {
        "introduction":  <DocumentationPage myMarkdownFile={require("../documentation/introduction/introduction.md")}/>,
        "actions":  <DocumentationPage myMarkdownFile={require("../documentation/basics/actions.md")}/>,
        "environment":  <DocumentationPage myMarkdownFile={require("../documentation/basics/environmentalConstants.md")}/>,
        "mana":  <DocumentationPage myMarkdownFile={require("../documentation/basics/mana.md")}/>,
        "variables":  <DocumentationPage myMarkdownFile={require("../documentation/basics/variables.md")}/>,
        "booleans": <DocumentationPage myMarkdownFile={require("../documentation/basics/booleans.md")}/>,
        "methods": <DocumentationPage myMarkdownFile={require("../documentation/basics/methods.md")}/>,
        "moreArrays": <DocumentationPage myMarkdownFile={require("../documentation/basics/moreArrays.md")}/>,
        "loops": <DocumentationPage myMarkdownFile={require("../documentation/basics/loops.md")}/>,
        "mathFunctions": <DocumentationPage myMarkdownFile={require("../documentation/basics/mathFunctions.md")}/>,
        "gamesOverview": <DocumentationPage myMarkdownFile={require("../documentation/gamesOverview/gameBasics.md")}/>,
    }



    return (
        <>
            <CoreNavBar loggedIn={localStorage.getItem('access_token') !== null}/>
            <div className="flex flex-row">
                <div className="col-3">
                    <Accordion defaultActiveKey={['0']} alwaysOpen={true} flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Botlang Documentation</Accordion.Header>
                            <Accordion.Body className="w-100 pl-0 pr-5 pt-0 pb-0 mr-0 ml-5 mt-0 mb-0">
                                <DocsItem identifier={"introduction"} displayText={"Introduction"}/>
                                <Accordion defaultActiveKey={['1']} alwaysOpen={true}>
                                    <DocsCategory sectionHeader={"Language Basics"} eventKey={"1"} identifiers={["actions", "variables", "environment", "mana",  "booleans", "methods", "moreArrays", "loops", "mathFunctions"]} displayTexts={["Actions", "Variables", "Environment", "Mana", "Booleans", "Methods", "Array Ops", "Loops", "Math Functions"]}/>
                                    <DocsCategory sectionHeader={"Games Overview"} eventKey={"2"} identifiers={["gamesOverview"]} displayTexts={["Overview"]}/>
                                </Accordion>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="col-9 p-4">
                    {pages[selectedElement]}
                </div>
            </div>
        </>
    );
}
export default DocumentationHub