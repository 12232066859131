import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {useNavigate} from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";

const CoreNavBar = ({transparent, loggedIn}) => {
    const navigate = useNavigate();
    const logout = () => navigate('/logout');
    const login = () => navigate('/login');
    const home = () => navigate('/');
    const build = () => navigate('/build');
    const battle = () => navigate('/arena');
    const leaderboard = () => navigate('/leaderboard');
    const docs = () => navigate('/docs');
    const roadmap = () => navigate('/roadmap');

    return (
        <Navbar expand="lg" className={
            transparent ? "bg-body-tertiary front transparent text-light" :
                "bg-gradient-to-r from-green-500 via-green-400 to-blue-300 text-light front"
        }>
            <Container>
                <Navbar.Brand href="#" onClick={home} className={"flex flex-row"}>
                    <img
                        alt=""
                        src="./images/bot2-nb.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />
                    <div className={"px-1 flex flex-row"}>{' '}Bitbots.io <h6><b>Beta</b></h6></div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link onClick={home}>Home</Nav.Link>
                        <Nav.Link onClick={build}>Build</Nav.Link>
                        <Nav.Link onClick={battle}>Battle</Nav.Link>
                        <Nav.Link onClick={leaderboard}>Leaderboard</Nav.Link>
                        <Nav.Link onClick={docs}>Learn</Nav.Link>
                        <Nav.Link onClick={roadmap}>Roadmap</Nav.Link>
                    </Nav>

                    {loggedIn ? (
                        <ProfileDropdown/>
                    ) : (
                        <Nav>
                            <Nav.Link onClick={login}>
                                Login
                            </Nav.Link>
                        </Nav>)
                    }


                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CoreNavBar;
