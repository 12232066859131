import axios from "axios";
import React, {useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import hostName from "../../constants/hosts";
import CoreNavBar from "../nav/CoreNavBar";

const showErrorToast = (msg, timer) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
        position: "top-right",
        autoClose: timer ? timer : 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

export const Signup = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [err, setErr] = useState(null);
    const navigate = useNavigate();
    const host = hostName

    const login = () => navigate('/login');
    const submit = async e => {
        e.preventDefault();
        setErr(null);
        if (password === passwordConfirm) {
            const user = {
                username: username,
                password: password
            };

            const {dat} =  await axios.post(host + '/users/', user,{headers: {
                    'Content-Type': 'application/json'
                }}, {withCredentials: true})
                .then(function (response) {
                    let data = response.data
                    localStorage.clear();
                    localStorage.setItem('access_token', data.token.access);
                    localStorage.setItem('refresh_token', data.token.refresh);
                    axios.defaults.headers.common['Authorization'] =
                        `Bearer ${data.token.access}`;
                    window.location.href = '/'
                })
                .catch((err) => {
                    if (err.response.status >= 400 && err.response.status < 500) {
                        let error = err.response ? err.response.data : err;
                        let status = error.status ? error.status : "Error on signup, please contact support."
                        setErr(status);
                    } else {
                        let error = err.response ? err.response.data : err;
                        setErr(error);
                    }

                });


        } else {
            showErrorToast("Password mismatch.")
        }
    }
    return(
        <>
        <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <CoreNavBar loggedIn={false}/>
        <div className="Auth-form-container bg-gradient-to-r from-green-500 via-green-400 to-blue-300 text-light p-5 vh-100">
            <form className="Auth-form" onSubmit={submit}>
                <div className="Auth-form-content">
                    <h3 className="Auth-form-title">Sign Up</h3>
                    <div className="form-group mt-3">
                        <label>Username: </label>
                        <input className="form-control mt-1"
                               placeholder="Enter Username"
                               name='username'
                               type='text' value={username}
                               required
                               onChange={e => setUsername(e.target.value)}/>
                    </div>
                    <div className="form-group mt-3">
                        <label>Password: </label>
                        <input name='password'
                               type="password"
                               className="form-control mt-1"
                               placeholder="Enter password"
                               value={password}
                               required
                               onChange={e => setPassword(e.target.value)}/>
                    </div>
                    <div className="form-group mt-3">
                        <label>Confirm Password: </label>
                        <input name='passwordConfirm'
                               type="password"
                               className="form-control mt-1"
                               placeholder="Confirm password"
                               value={passwordConfirm}
                               required
                               onChange={e => setPasswordConfirm(e.target.value)}/>
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <div className="flex flex-row">
                            <button type="submit"
                                    className="btn btn-primary px-4 py-2">Submit
                            </button>
                            <button className="btn btn-primary px-4 py-2 mx-2" onClick={login}>
                                Back to login
                            </button>
                        </div>
                    </div>
                    <div>
                        {err ?
                            (<p className={"text-red-600"}>{err}</p>) :
                            (<></>)
                        }
                    </div>
                </div>
            </form>
        </div>
        </>
    )
}
