import Markdown from 'react-markdown'
import {useState} from "react";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import { materialDark as codeStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';



const DocumentationPage = ({myMarkdownFile}) => {
    const [fileText, setFileText] = useState("");
    const host = window.location.hostname === "localhost" ? "localhost:3000" : "bitbots.io"


    fetch(myMarkdownFile)
        .then(response => response.text())
        .then(text => setFileText(text))

    return(
        <>
            <Markdown components= {{
                code(props) {
                    const {children, className, node, ...rest} = props
                    const match = /language-(\w+)/.exec(className || '')
                    return match ? (
                        <SyntaxHighlighter
                            {...rest}
                            PreTag="div"
                            children={String(children).replace(/\n$/, '')}
                            language={match[1]}
                            style={codeStyle}
                        />
                    ) : (
                        <code {...rest} className={className}>
                            {children}
                        </code>
                    )
                }
            }}>
                {fileText.replaceAll("{{HOST}}", host)
            }</Markdown>
        </>
    );
}
export default DocumentationPage