import {classnames} from "../../utils/general";
import React, {useEffect, useState} from "react";
import axios from "axios";
import BotSelectList from "../../models/BotSelectList";
import ArenaSelectList from "../../models/ArenaSelectList";
import ConfettiExplosion from 'react-confetti-explosion';

import {Col, Container, Row} from 'react-grid-system';
import BotDropdown from "./BotDropdown";
import CoreNavBar from "../nav/CoreNavBar";
import {Button, Form, Offcanvas} from "react-bootstrap";
import OutputWindow from "../botBuilder/OutputWindow";
import Modal from "react-bootstrap/Modal";
import hostName from "../../constants/hosts";

const ArenaView = () => {
    const host = hostName

    var myArr = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    function initMatrix() {
        var arr = [];
        for (var i = 0; i < 21; i++) {
            arr[i] = Array.from(myArr);
        }
        return arr;
    }

    const [arenaMatrix, setMatrix] = useState(initMatrix);
    const [selectedBot1, setSelectedBot1] = useState(null)
    const [selectedBot2, setSelectedBot2] = useState(null)
    const [selectedArena, setSelectedArena] = useState(null)
    const [arenaName, setArenaName] = useState(null);


    const [hasBots, setHasBots] = useState(false)
    const [hasUserBots, setHasUserBots] = useState(false)
    const [bots, setBots] = useState(getBotList( host + '/friends/bots/get'));
    const [userBots, setUserBots] = useState(getUserBotList(host + '/bots/my-bots'));

    const [arenas, setArenas] = useState(getArenaList);
    const [isPlaying, setIsPlaying] = useState(false);
    const [clock, setClock] = useState("5:00")
    const [allegianceCount, setAllegianceCount] = useState([20,20])
    const [botScores, setBotScores] = useState([0,0])
    const [arenaBotNames, setArenaBotNames] = useState(["",""])
    const [showConsole, setShowConsole] = useState(false);
    const [consoleOutput, setConsoleOutput] = useState("console output")
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showWinnerModal, setShowWinnerModal] = useState(false);
    const [winner, setWinner] = useState(null);
    const [confetti, setConfetti] = useState(false);

    const [showMyBots, setShowMyBots] = useState(true);
    const [showFriendBots, setShowFriendBots] = useState(true);
    const [showStockBots, setShowStockBots] = useState(true);

    const handleCloseModal = () => setShowDeleteModal(false);
    const handleShowModal = () => setShowDeleteModal(true);
    const handleCloseWinnerModal = () => setShowWinnerModal(false);
    const handleShowWinnerModal = () => setShowWinnerModal(true);

    const handleCloseConsole = () => setShowConsole(false);
    const handleShowConsole = () => setShowConsole(true);

    function imageGen(allegiance, style) {
        if (allegiance === 1) {
            return <>
                <img style={style} src={"./images/bot1.png"}/>
                <div className={"position-absolute bg-blue-500 opacity-25 h-100 w-100 top-0 left-0 rounded-circle"}></div>
            </>
        } else if (allegiance === 2) {
            return <>
                <img style={style} src={"./images/bot2.png"}/>
                <div className={"position-absolute bg-blue-500 h-100 opacity-25 w-100 top-0 left-0 rounded-circle"}></div>
            </>
        } else {
            return <>
                <img style={style} src={"./images/blank.png"}/>
            </>
        }
    }

    function unshieldedImageGen(allegiance, style) {
        if (allegiance === 1) {
            return <img style={style} src={"./images/bot1.png"} />
        } else if (allegiance === 2) {
            return <img style={style} src={"./images/bot2.png"} />
        } else {
            return <img style={style} src={"./images/blank.png"} />
            // return <div style={{
            //     maxHeight: '30px',
            //     maxWidth: '30px'
            // }}>{i},{j}</div>
        }
    }

    function getPic(allegiance, facingDirection, shield, i, j) {
        let style = {
            transform: ''
        };
        let stringified = JSON.stringify(facingDirection)

        if (stringified === JSON.stringify([-1, 0])){
            style = {
                transform: 'rotate(90deg)',
            }
        }  else if (stringified === JSON.stringify([1, 0])){
            style = {
                transform: 'rotate(270deg)',
            }
        }else if (stringified === JSON.stringify([0, 1])){
            style = {
                transform: 'rotate(180deg)',
            }
        }

        if (shield > 0) {
            return <div className={"tile"}>
                <div className={"position-relative"}>
                    {imageGen(allegiance, style)}
                </div>
                <div className={"tile-overlay justify-content-center align-items-center flex text-white"}>
                    <div>{j},{Math.abs(20 - i)}</div>
                </div>
            </div>
        }

        return <div className={"tile"}>

            {unshieldedImageGen(allegiance, style)}
            <div className={"tile-overlay justify-content-center align-items-center flex text-white"}>
                <div>{j},{Math.abs(20-i)}</div>
            </div>
        </div>


    }



    useEffect(() => {
        if (isPlaying) {
            getInstructions(selectedArena)
        }
        const interval = setInterval(() =>
            isPlaying ? getInstructions(selectedArena) : null, 1000)

        return () => {
            clearInterval(interval);
        }

    }, [isPlaying])

    function getArenaList() {
        const options = {
            method: "GET",
            url: host + '/arenas/get',
        };

        return axios
            .request(options)
            .then(function (response) {

                let arenaSelectList = response.data
                var arenasJson = []
                for (var i=0;i<arenaSelectList.arenas.length;i++){
                    arenasJson[i] = {
                        'id': arenaSelectList.arenas[i].id,
                        'name':arenaSelectList.arenas[i].name ? arenaSelectList.arenas[i].name : arenaSelectList.arenas[i].id,
                        'label': arenaSelectList.arenas[i].name ? arenaSelectList.arenas[i].name : arenaSelectList.arenas[i].id,
                        'value': arenaSelectList.arenas[i].id,
                    }
                }
                setArenas(arenasJson)
                return arenasJson
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                // get error status
                let status = err.response.status;
                console.log("status", status);
                if (status === 429) {
                    console.log("too many requests", status);
                }
                console.log("catch block...", error);
                return []
            });
    }

    function createNewArena() {
        const formData = {
            bot1Id: selectedBot1.id,
            bot2Id: selectedBot2.id,
            arenaName: arenaName
        }
        const options = {
            method: "POST",
            url: host + '/arena/new',
            data: formData
        };

        return axios
            .request(options)
            .then(function (response) {
                window.location.reload();
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                // get error status
                let status = err.response.status;
                console.log("status", status);
                if (status === 429) {
                    console.log("too many requests", status);
                }
                console.log("catch block...", error);
                return []
            });
    }


    function getBotList(url) {
        const options = {
            method: "GET",
            url: url,
        };

        if (!hasBots){
            return axios
                .request(options)
                .then(function (response) {

                    let botSelectList = response.data;
                    var botsJson = []
                    for (var i=0;i<botSelectList.bots.length;i++){
                        botsJson[i] = {
                            'id': botSelectList.bots[i].id,
                            'name': botSelectList.bots[i].name,
                            'label': botSelectList.bots[i].name,
                            'value': botSelectList.bots[i].id,
                        }
                    }
                    setHasBots(true);
                    setBots(botsJson);
                    return botsJson
                })
                .catch((err) => {
                    let error = err.response ? err.response.data : err;
                    // get error status

                    let status = err.response.status;
                    console.log("status", status);
                    if (status === 429) {
                        console.log("too many requests", status);
                    }
                    console.log("catch block...", error);
                    return []
                });
        }



    }

    function getUserBotList(url) {
        const options = {
            method: "GET",
            url: url,
        };

        if (!hasUserBots){
            return axios
                .request(options)
                .then(function (response) {
                    let botSelectList = response.data;
                    var botsJson = []
                    for (var i=0;i<botSelectList.bots.length;i++){
                        botsJson[i] = {
                            'id': botSelectList.bots[i].id,
                            'name': botSelectList.bots[i].name,
                            'label': botSelectList.bots[i].name,
                            'value': botSelectList.bots[i].id,
                        }
                    }
                    setHasUserBots(true);
                    setUserBots(botsJson)
                    return botsJson
                })
                .catch((err) => {
                    let error = err.response ? err.response.data : err;
                    // get error status
                    let status = err.response.status;
                    console.log("status", status);
                    if (status === 429) {
                        console.log("too many requests", status);
                    }
                    console.log("catch block...", error);
                    return []
                });
        }
    }

    function fancyTimeFormat(duration) {
        // Hours, minutes and seconds
        const hrs = ~~(duration / 3600);
        const mins = ~~((duration % 3600) / 60);
        const secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;

        return ret;
    }

    const getInstructions = (arena) => {

        const options = {
            method: "GET",
            url: host + '/arena/'+ arena +'/next',
        };

        let newMatrix = initMatrix()

        axios
            .request(options)
            .then(function (response) {
                let instructions =response.data
                let allegiance_count = [0,0]
                instructions.instructions.map((bot, i) => {
                        allegiance_count[bot.allegiance] += 1
                        newMatrix[Math.abs(bot.position[1] - 20)][bot.position[0]] = {
                            allegiance: bot.allegiance + 1,
                            direction: bot.facing_direction,
                            shield: bot.shield
                        }
                    }
                )
                setMatrix(newMatrix);
                setConsoleOutput(instructions.logs);
                setClock(fancyTimeFormat(300 - instructions.round));
                setAllegianceCount(allegiance_count);
                let bot1Name = instructions.bot_1_name;
                let bot2Name = instructions.bot_2_name;
                setArenaBotNames([bot1Name, bot2Name]);
                setBotScores([instructions.bot_1_score, instructions.bot_2_score]);
                if (instructions.winner != null){
                    setWinner(instructions.winner);
                    setShowWinnerModal(true);
                    setIsPlaying(false);
                    setConfetti(true);
                }

            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                // get error status
                let status = err.response.status;
                console.log("status", status);
                if (status === 429) {
                    console.log("too many requests", status);
                }
                console.log("catch block...", error);
            });
    };
    const resetArena = (arena) => {
        const options = {
            method: "POST",
            url: host + '/arena/'+ arena +'/reset',
        };

        let newMatrix = initMatrix()

        axios
            .request(options)
            .then(function (response) {
                let instructions =response.data

                instructions.instructions.map((bot, i) => {
                        newMatrix[Math.abs(bot.position[1] - 20)][bot.position[0]] = {
                            allegiance: bot.allegiance + 1,
                            direction: bot.facing_direction,
                            shield: bot.shield
                        }
                    }
                )
                setMatrix(newMatrix);
                setClock("5:00");
                setAllegianceCount([20,20]);
                setConsoleOutput("");
                setBotScores([0,0]);
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                // get error status
                let status = err.response.status;
                console.log("status", status);
                if (status === 429) {
                    console.log("too many requests", status);
                }
                console.log("catch block...", error);
            });
    };
    const deleteArena = (arena) => {
        const options = {
            method: "POST",
            url: host + '/arena/'+ arena +'/delete',
        };

        axios
            .request(options)
            .then(function (response) {
                window.location.reload();
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                // get error status
                let status = err.response.status;
                console.log("status", status);
                if (status === 429) {
                    console.log("too many requests", status);
                }
                console.log("catch block...", error);
            });
    };

    const onSelectChange = (sb1) => {
        setSelectedBot1(sb1);
    };
    const onSelectChange2 = (sb2) => {
        setSelectedBot2(sb2);
    };
    const onSelectArena = (arena) => {
        setSelectedArena(arena.id);
    };

    const filterEnemyBots = () => {
        if (hasBots && hasUserBots) {
            if (showMyBots && showFriendBots) {
                return bots.concat(userBots)
            } else if (showMyBots) {
                return userBots
            } else if (showFriendBots) {
                return bots
            } else {
                return []
            }
        } else if (hasUserBots) {
            return showMyBots ? userBots : []
        } else if (hasBots) {
            return showFriendBots ? bots : []
        } else {
            return []
        }
    }
    if(localStorage.getItem('access_token') === null){
        window.location.href = '/login'
    } else {
        return (
            <>
                <CoreNavBar loggedIn={true}/>
                <Offcanvas show={showConsole} onHide={handleCloseConsole} backdrop={false} placement={"bottom"} scroll={true}>
                    <Offcanvas.Body>
                        <OutputWindow outputDetails={consoleOutput} fullHeight={true}/>
                    </Offcanvas.Body>
                </Offcanvas>

                {/*Delete arena*/}
                <Modal show={showDeleteModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Arena?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete the selected Arena?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={() => deleteArena(selectedArena)}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*Winner Modal*/}
                <Modal show={showWinnerModal} onHide={handleCloseWinnerModal} backdrop={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Winner!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Bot {winner} has won the match - reset the arena to try again!
                        <div className={'cent'}>
                            {confetti && <ConfettiExplosion force={0.8} duration={3000} particleCount={250} width={1600} />}
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={handleCloseWinnerModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


                <Container
                    className="container w-100 me-1 mb-3 border-bottom-2 border-end-2 border-start-2 border-black rounded-bottom-2 shadow-[0px_4px_0px_4px_rgba(0,0,0)] px-4 py-2 bg-white bg-dark-subtle ">
                    <div className="row w-100 mx-1 pb-0 pt-2">
                        <button
                            onClick={() => setIsPlaying(!isPlaying)}
                            className={classnames(
                                isPlaying ? "col-sm border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0 bg-gradient-to-r from-orange-500 via-orange-400 to-yellow-300 text-light "
                                    : "col-sm border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0 bg-gradient-to-r from-green-500 via-green-400 to-green-300 text-light ",
                            )}
                        > {isPlaying ? "Pause" : "Play"}
                        </button>
                        <div className="col-sm px-4 py-2">
                            <label>Available Arenas: </label>
                            <BotDropdown onSelectChange={onSelectArena} botList={arenas} disabled={isPlaying}
                                         placeholder={"Select"}/>
                        </div>
                        <div className="col-sm px-4 py-2">
                            <label>Your Bots: </label>
                            <BotDropdown onSelectChange={onSelectChange} botList={userBots} disabled={isPlaying}
                                         placeholder={"Select"}/>
                        </div>
                        <div className="col-sm px-4 py-2">
                            <div className={"flex flex-row"}>
                                <div className={"col"}>
                                    <label>Enemy Bots: </label>
                                    <BotDropdown onSelectChange={onSelectChange2} botList={filterEnemyBots()}
                                                 disabled={isPlaying}
                                                 placeholder={"Select"}/>
                                </div>
                            </div>

                        </div>
                        <div className={"col p-0 m-0 nm"}>
                            <label>My bots</label>
                            <Form className={"nm"}>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    checked={showMyBots}
                                    onChange={e => setShowMyBots(e.target.checked)}
                                    id="custom-switch"
                                    className={"form-switch-sm nm"}
                                />
                            </Form>
                            <label>Friends</label>
                            <Form>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    checked={showFriendBots}
                                    onChange={e => setShowFriendBots(e.target.checked)}
                                    id="custom-switch"
                                    className={"form-switch-sm nm"}
                                />
                            </Form>
                        </div>
                        <div className="col-sm px-4 py-2">
                            <form>
                                <label> Arena Name:
                                    <input
                                        className="form-control mt-0 rounded-md border-2 z-10 border-black shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2  hover:shadow transition duration-200 bg-white"
                                        onChange={e => setArenaName(e.target.value)}/>
                                </label>
                            </form>
                        </div>
                        <button
                            onClick={createNewArena}
                            disabled={(isPlaying || !arenaName || !(selectedBot1 && selectedBot2))}
                            className={classnames(
                                (isPlaying || !arenaName || !(selectedBot1 && selectedBot2)) ? "col-sm border-2 border-black z-10 rounded-md bg-white flex-shrink-0 bg-gradient-to-r from-gray-500 via-gray-500 to-gray-500 text-gray-400"
                                    : "col-sm border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0 bg-gradient-to-r from-red-500 via-red-400 to-yellow-300 text-light"
                            )}
                        >
                            {"Create new arena"}
                        </button>
                    </div>
                </Container>
                <div className="flex flex-row">
                    <div className="flex flex-row">
                        <div className={"mx-3"}>
                            <div
                                className="w-100 container w-100 me-1 mb-3 border-bottom-2 border-end-2 border-start-2 border-black rounded-md shadow-[0px_0px_0px_4px_rgba(0,0,0)] px-4 py-2 bg-white bg-dark-subtle ">
                                <div className="row w-100">
                                    <h3>{clock}</h3>
                                </div>
                                <div className="row w-100">
                                    <div>
                                        <div className={"row w-100"}>
                                            <div className={"col-auto"}>{arenaBotNames[0]}</div>
                                        </div>
                                        <div className={"row w-100"}>
                                            <h5><img
                                                alt=""
                                                src="./images/bot1.png"
                                                width="20"
                                                height="20"
                                                className="d-inline-block align-top"
                                            /> {allegianceCount[0]} - {botScores[0]}
                                            </h5>
                                        </div>
                                    </div>
                                    <div>
                                        <h5>
                                            VS
                                        </h5>
                                    </div>
                                    <div>
                                        <div className="row w-100">
                                            <div className={"col-auto"}>{arenaBotNames[1]}</div>
                                        </div>
                                        <div className="row w-100">
                                            <h5><img
                                                alt=""
                                                src="./images/bot2.png"
                                                width="20"
                                                height="20"
                                                className="d-inline-block align-top"
                                            /> {allegianceCount[1]} - {botScores[1]} </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                onClick={() => setShowConsole(!showConsole)}
                                className={classnames(
                                    showConsole ? "w-100 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0 bg-gradient-to-r from-orange-500 via-orange-400 to-yellow-300 text-light "
                                        : "col-sm border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0 bg-gradient-to-r from-blue-500 via-blue-400 to-blue-300 text-light ",
                                )}
                            > {showConsole ? (
                                <>Hide Console</>
                            ): (
                                <>Show Console</>
                            )}
                            </button>
                            <div className={"p-1"}></div>
                            {selectedArena ? (
                                <div>
                                    <button
                                        onClick={() => resetArena(selectedArena)}
                                        className={classnames(
                                            "col-sm mt-4 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0 bg-gradient-to-r from-orange-500 via-orange-400 to-orange-300 text-light ",
                                        )}
                                    > Reset Arena
                                    </button>
                                    <div className={"p-1"}></div>
                                    <button
                                        onClick={() => handleShowModal()}
                                        className={classnames(
                                            "col-sm mt-4 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0 bg-gradient-to-r from-red-500 via-red-400 to-red-300 text-light ",
                                        )}
                                    > Delete Arena
                                    </button>
                                </div>
                            ) : (
                                <div></div>
                            )}

                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className={"col-11"}>
                            <Container className="border-2 border-black rounded-md bg-light mx-0">
                                {arenaMatrix.map((row, i) => (
                                    <Row className="border-top border-bottom py-1" key={i}>
                                        {row.map((col, j) => (
                                            <Col className="border-end border-start" key={j}>
                                                {getPic(col.allegiance, col.direction, col.shield, i, j)}
                                            </Col>
                                        ))}
                                    </Row>
                                ))}
                            </Container>
                        </div>
                    </div>


                </div>

            </>
        );
    }
}

export default ArenaView;
