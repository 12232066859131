import CoreNavBar from "../nav/CoreNavBar";
import React, {useState} from "react";
import {Container} from "react-grid-system";
import axios from "axios";
import hostName from "../../constants/hosts";
import {Button, Card, OverlayTrigger, Tooltip} from "react-bootstrap";
import {PersonAdd} from "react-bootstrap-icons";
import BotLoadModal from "../botBuilder/BotLoadModal";
import FindFriendsModal from "./FindFriendsModal";

const ProfilePage = () => {

    const [hasProfile, setHasProfile] = useState(false);
    const [profile, setProfile] = useState(getProfile(hostName + '/current_user/'))
    const friendsPlaceholder = ["Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ","Friend ",]
    const [showFriendsModal, setShowFriendsModal] = useState(false)

    const [hasFetchedFriends, setHasFetchedFriends] = useState(false);
    const [friends, setFriends] = useState(getUserFriendRequests());
    const [hasFriends, setHasFriends] = useState(false);

    const handleClose = () => setShowFriendsModal(false);
    const renderTooltip = (props) => (
        <Tooltip id="tooltip-top" {...props}>
            Achievement Desc.
        </Tooltip>
    );

    function getUserFriendRequests() {
        const options = {
            method: "GET",
            url: hostName + "/friends",
        };

        if (!hasFetchedFriends) {
            return axios
                .request(options)
                .then(function (response) {
                    let friendRequestList = response.data;
                    var friendsJson = []
                    for (var i=0;i<friendRequestList.friends.length;i++){
                        friendsJson[i] = {
                            'id': friendRequestList.friends[i].id,
                            'username': friendRequestList.friends[i].username
                        }
                    }
                    //
                    // for (var j = friendsJson.length+1; j<25; j++){
                    //     friendsJson[j] = testFriendsJson
                    // }


                    setFriends(friendsJson);
                    setHasFetchedFriends(true);
                    if (friendsJson.length > 0) {
                        setHasFriends(true);
                    }
                    return friendsJson
                })
                .catch((err) => {
                    let error = err.response ? err.response.data : err;
                    // get error status
                    let status = err.response.status;
                    console.log("status", status);
                    if (status === 429) {
                        console.log("too many requests", status);
                    }
                    console.log("catch block...", error);
                    return []
                });
        }
    }


    function getProfile(url) {
        const options = {
            method: "GET",
            url: url,
        };

        if (!hasProfile){
            return axios
                .request(options)
                .then(function (response) {
                    let profile = response.data;

                    let profileData = {
                        'username': profile.username
                    }
                    setHasProfile(true);
                    setProfile(profileData);
                    return profileData
                })
                .catch((err) => {
                    let error = err.response ? err.response.data : err;
                    // get error status
                    let status = err.response.status;
                    console.log("status", status);
                    if (status === 429) {
                        console.log("too many requests", status);
                    }
                    console.log("catch block...", error);
                    return []
                });
        }
    }
    if(localStorage.getItem('access_token') === null){
        window.location.href = '/login'
    }
    else {
        return (
            <>
                <FindFriendsModal show={showFriendsModal} handleClose={handleClose}/>
                <CoreNavBar loggedIn={localStorage.getItem('access_token') !== null}/>
                <div className={"row flex-row m-4"}>
                    <div className={"m-0 col-9"}>
                        <div className={"row flex-row"}>
                            <img className="rounded-md d-inline-block align-top w-25 h-25 border-3"
                                 src={"./images/bot1.png"}
                                 alt="user pic"
                            />
                            <div className={"col-md-9"}>
                                <h2>{profile.username}</h2>
                                <p>
                                    Profile details and editing coming soon!
                                </p>
                            </div>
                        </div>
                        <hr/>
                        <div className={"row flex-row"}>
                            <div className={"row flex-row"}>
                                <h3>Achievements</h3>
                            </div>
                            <div className={"row flex-row"}>
                                <div className="pull-left col-auto">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{show: 250, hide: 400}}
                                        overlay={renderTooltip}
                                    >
                                        <img className="rounded-circle d-inline-block align-top"
                                             src={"./images/badge.png"}
                                             alt="user pic"
                                             width="50"
                                             height="50"
                                        />
                                    </OverlayTrigger>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={"col-3 m-0 max-h-100"}>
                        <Card className={"mt-4 max-h-100"}>
                            <Card.Header>
                                <div className={"row flex-row"}>
                                    <div className={"col-6"}>Friends</div>
                                    <div className={"col-3 ml-auto"}>
                                        <Button onClick={() => setShowFriendsModal(true)}>
                                            <PersonAdd></PersonAdd>
                                        </Button>
                                    </div>
                                </div>

                            </Card.Header>
                            <Card.Body>
                                {hasFriends ? (
                                    <ul className={"list-group"}>
                                        {friends.map((row, i) => (
                                            <li className={"list-item"}>
                                                {row.username}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <Button onClick={() => setShowFriendsModal(true)} variant={"success w-100"}>
                                        Add Friends
                                    </Button>
                                )}

                            </Card.Body>
                        </Card>
                    </div>
                </div>


            </>
        )
    }
}

export default ProfilePage
