import "./App.css";
import BotBuilder from "./components/botBuilder/BotBuilder";
import { Routes,Route} from 'react-router-dom';
import React from "react";
import ArenaView from "./components/arena/ArenaView";
import {Login} from "./components/auth/Login";
import {Logout} from "./components/auth/Logout";
import {Signup} from "./components/auth/Signup";
import Home from "./components/home/Home";
import DocumentationHub from "./components/documentation/DocumentationHub"
import TestArena from "./components/arena/TestArena"
import ProfilePage from "./components/profile/ProfilePage";
import RequestsPage from "./components/requests/RequestsPage";
import LeaderboardPage from "./components/leaderboard/LeaderboardPage";
import Roadmap from "./components/roadmap/Roadmap";
import Support from "./components/support/Support";

function App() {
    return(
        <Routes>
            <Route  path="/" element={<Home/>} />
            <Route  path="/build" element={<BotBuilder/>} />
            <Route  path="/arena" element={<ArenaView/>} />
            <Route path="/login" element={<Login/>}/>
            <Route path="/signup" element={<Signup/>}/>
            <Route path="/logout" element={<Logout/>}/>
            <Route path="/docs" element={<DocumentationHub/>}/>
            <Route path="/test" element={<TestArena/>}/>
            <Route path="/profile" element={<ProfilePage/>}/>
            <Route path="/requests" element={<RequestsPage/>}/>
            <Route path="/leaderboard" element={<LeaderboardPage/>}/>
            <Route path="/roadmap" element={<Roadmap/>}/>
            <Route path="/support" element={<Support/>}/>
        </Routes>
    )
}

export default App;
