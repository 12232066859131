import React from "react";
import Select from "react-select";
import {customStyles} from "../../constants/customStyles";

const BotDropdown = ({ onSelectChange, botList, disabled, placeholder }) => {

    return ( botList.length > 0 &&
        <Select
            className={"z-10"}
            isDisabled={disabled ?? false}
            placeholder={placeholder}
            options={botList}
            styles={customStyles}
            defaultValue={null}
            onChange={(selectedOption) => onSelectChange(selectedOption)}
        />
    );
};

export default BotDropdown;
