import CoreNavBar from "../nav/CoreNavBar";
import {Card} from "react-bootstrap";

const Support = () => {
    return (
        <>
            <CoreNavBar loggedIn={localStorage.getItem('access_token') !== null}/>
            <div className={"Auth-form-container bg-gradient-to-r from-green-500 via-green-400 to-blue-300 text-light px-5 py-0"}>
                <Card className={"Auth-form py-1"}>
                    <Card.Body>
                        (I haven't built a report system yet). Email <a href={"mailto: support@bitbots.io"}>support@bitbots.io</a> and I'll get to your issue as soon as I can!
                    </Card.Body>

                </Card>
            </div>
        </>

    );
}

export default Support;