import CoreNavBar from "../nav/CoreNavBar";
import React, {useEffect} from "react";
import Container from "react-bootstrap/Container";
import {useNavigate} from "react-router-dom";
import CodeEditorWindow from "../botBuilder/CodeEditorWindow";
import {defineTheme} from "../../lib/defineTheme";


const Home = () => {

    const javascriptDefault = `        # super useful code
        def join_us(arg):
            if _USER == "cool":
                return arg + "Challenge your friends, and build your skills."
            elif _USER == "unsure":
                return arg + "There's nothing to lose!"
            else:
                return "We'd love to have you :)"
            END
        END
        
        let val = join_us("Learn to code in a fun and competitive way. ")
        print(val)
        `;

    const navigate = useNavigate();
    const build = () => navigate('/build');
    const battle = () => navigate('/arena');
    const learn = () => navigate('/docs');

    useEffect(() => {
        defineTheme("oceanic-next")
    }, []);

    return (
        <>
            <CoreNavBar
                transparent={true} loggedIn={localStorage.getItem('access_token') !== null}
            />
            <Container className="slanted container-fluid p-0 w-100 slanted-banner">
                <div className="bg-gradient-to-r from-green-500 via-green-400 to-blue-300 text-light p-5 h-100">
                    <div className="slanted-contents container">
                        <h1 className="display-4">Write code, fight code.</h1>
                        <hr/>
                        <p className="display-6">Build your bot, enter the arena</p>
                    </div>
                </div>
            </Container>
            <Container className="container vh-50 p-5 w-100 mt-5 mb-5">
                <div className="row h-100 p-0 w-100">
                    <div className="col-sm p-0">
                        <h1 className="display-5 fw-bolder">Get started.</h1>
                        <p>Write code to determine the behavior of your bots - making them as efficient (and deadly) as possible. Test your bots against your friends, and flex your programming prowess by dominating the arena.</p>
                    </div>
                    <div className="col-sm p-0">
                        <div className="container h-100">
                            <CodeEditorWindow
                                code={javascriptDefault}
                                onChange={()=>{}}
                                language="python"
                                theme="oceanic-next"
                                height="25vh"
                            />
                        </div>
                    </div>
                </div>
            </Container>
            <Container className="slanted-r container-fluid p-0 mt-5 mx-0 overflow-hidden bigly big-button-container">
                <Container className="d-flex flex-column bigly p-0 left big-button-container">
                    <div className="row bigly h-100">
                        <div onClick={build} className="col-6 slanted-x m-0 p-0 pointer">
                            <div className="bg-gradient-to-r from-purple-500 via-blue-400 to-blue-300 text-light p-5 big-button-h align-items-center d-flex">
                                <div className="slanted-x-contents container cent pb-4">
                                    <h1>Build</h1>
                                </div>
                            </div>
                        </div>
                        <div onClick={battle} className="col-6 slanted-x m-0 p-0 pointer">
                            <div className="bg-gradient-to-r from-red-500 via-red-400 to-yellow-300 text-light p-5 big-button-h align-items-center d-flex">
                                <div className="slanted-x-contents container cent pt-4">
                                    <h1>Fight</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Container>

            <Container className="container vh-50 p-5 w-100 mt-5 mb-5">
                <div className="row h-100 p-0 w-100 pt-5">
                    <div className="col p-0">
                        <h1 className="display-5 fw-bolder">Learn more</h1>
                        <p>View our Botlang language documentation, learn how the arena works, and more!</p>
                    </div>
                    <div className="col p-0 ">
                        <div className="container h-100 align-items-center justify-content-center d-flex">
                            <button className="btn btn-lg button-l btn-outline-success" onClick={learn}>
                                Let's go!
                            </button>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );

}

export default Home;
