import {useEffect, useState} from "react"
import axios from "axios";
import hostName from "../../constants/hosts";
import {Login} from "./Login";
export const Logout = () => {
    const host = hostName

    useEffect(() => {
        (async () => {
            try {
                const {data} = await axios.post(host + '/auth/logout',{
                            refresh_token:localStorage.getItem('refresh_token')
                        } ,{headers: {'Content-Type': 'application/json'}},
                        {withCredentials: true});
                localStorage.clear();
           axios.defaults.headers.common['Authorization'] = null;
                window.location.href = '/login'
            } catch (e) {
                console.log('logout not working', e)
            }
        })();
    }, []);
    return (
        <div>
            <Login/>
        </div>
    )
}
